<template>
  <HomeMB v-if="layout == 4" />
  <HomePc v-else />
</template>

<script>
import HomeMB from "@/pages/mb/home/Home";
import HomePc from "@/pages/pc/home/Home";
import { provide, reactive } from "vue";
import BlockService from "@/services/block";
import DataService from "@/services/dosiin";
import BrandServices from "@/services/brand";
import { serialize } from "@/utlis/serialize";

export default {
  name: "home",
  components: {
    HomeMB,
    HomePc,
  },
  setup() {
    const state = reactive({
      banners: [],
      forcusBanner: [],
      categories: [],
      productsNewArrival: [],
      brandsNewArrival: [],
      categoriesProductsBest: [],
      brandsSugestion: [],
    });

    // getBanners(602);
    onIntersectionListCategory();
    async function getBanners(id) {
      const response = await BlockService.fetchBlocks(id);

      if (response && response.data) {
        state.banners = response.data;
      }
    }

    // async function onIntersectionCategoriesBest(){
    //     const response = await DataService.fetchData(serialize({getGroupCategriesBestSelling : true , type : 'best_selling'  }));
    //     if(response.data){
    //         state.categoriesProductsBest = response.data;
    //     }
    // }

    async function getFocusBanners() {
      // const response = await BlockService.fetchBlocks(id);
      // if (response && response.data) {
      //   state.forcusBanner = response.data;
      // }
      const response = await BrandServices.fetchBrandsSale({ sale_from: 40, sale_to: 80 });

      if (response && response.data) {
        state.forcusBanner = response.data.companies;
      }
    }

    async function onIntersectionListCategory() {
      const response = await DataService.fetchData(
        serialize({ type: "top", getCategory: true })
      );
      if (response && response.data) {
        state.categories = Object.values(response.data);
      }
    }

    async function onIntersectionBanners() {
      const response = await BrandServices.fetchBrands(
        serialize({
          sort_by: "random",
          get_banner: true,
          limit: 5,
          total_products_count: 20
        })
      );

      if (response && response.data) {
        state.banners = response.data.brands;
      }
    }

    async function onIntersectionBrandNew() {
      const response = await BrandServices.fetchBrands(
        serialize({
          sort_by: "id",
          sort_order: "desc",
          get_banner: true,
          get_follow: true,
          limit: 6,
        })
      );

      const response_ads = await BrandServices.fetchBrands(
        serialize({
          company_id: "591",
          get_banner: true,
          get_follow: true,
        })
      );

      if (response && response_ads.data) {
        state.brandsNewArrival = Object.values(response_ads.data.brands);
      }

      if (response && response.data) {
        state.brandsNewArrival.push(...response.data.brands);
        state.brandsNewArrival.splice(-1);
      }
    }

    async function onIntersectionBrandSugestion() {
      const response = await BrandServices.fetchBrands(
        serialize({
          sort_by: "random",
          sort_order: "desc",
          get_banner: true,
          get_follow: true,
          limit: 20,
          level_id: 20,
          status: "A",
        })
      );

      if (response && response.data) {
        state.brandsSugestion = Object.values(response.data.brands);
      }
    }

    async function onIntersectionProductNewArrival() {
      const response = await DataService.fetchData(
        serialize({ type: "new_arrival", distinct_company: true })
      );
      if (response && response.data) {
        state.productsNewArrival = Object.values(response.data.products);
      }
    }

    provide("state", state);
    provide("getFocusBanners", getFocusBanners);
    provide("onIntersectionBrandNew", onIntersectionBrandNew);
    // provide('onIntersectionCategoriesBest' , onIntersectionCategoriesBest);
    provide("onIntersectionListCategory", onIntersectionListCategory);
    provide("onIntersectionBrandSugestion", onIntersectionBrandSugestion);
    provide("onIntersectionProductNewArrival", onIntersectionProductNewArrival);
    provide("onIntersectionBanners", onIntersectionBanners);
  },
};
</script>
