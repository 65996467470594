<template>
  <div>
    <!-- <MainSlider
      :banners="state.banners"
      :options="options"
      :height="188"
      sectionClass="product-main-page-section main-slider-home"
      wrapperClass="product-main-page-slider dosiin_product-main-page"
      swiperItemImageClass="item-images-slider"
    /> -->

    <Intersection @onIntersection="onIntersectionBanners()">
      <MainSlider
        :banners="state.banners"
        :options="options"
        :height="188"
        ectionClass="product-main-page-section main-slider-home"
        wrapperClass="product-main-page-slider dosiin_product-main-page"
        swiperItemImageClass="item-images-slider"
        :templateBlock="false"
      />
    </Intersection>  

    <HotDeal />

    <!-- <Intersection @onIntersection="onIntersectionCategoriesBest()"> -->
    <SliderTabProductCategories
      title="Sản phẩm bán chạy"
      link="/new-arrival"
      :categories="state.categoriesProductsBest"
    />
    <!-- </Intersection> -->

    <Intersection @onIntersection="getFocusBanners()">
      <div class="banner-focus-slider">
        <h2 class="section-title">Tiêu điểm</h2>
        <MainSlider
          :banners="state.forcusBanner.slice(0,5)"
          :options="focusOptions"
          :height="188"
          sectionClass=""
          wrapperClass=""
          swiperItemImageClass="item-images-slider"
          :templateBlock="false"
        />
      </div>
    </Intersection>

    <CategoriesListSkeletons v-if="state.categories.length === 0" />
    <section class="page-section categories-intro-section scroll-icon">
      <h2 class="section-title">Top danh mục</h2>
      <ul class="all-categories_content">
        <template v-for="(cat, i) in state.categories">
          <li class="dosiin_all-categories all-categories" :key="i" v-if="i < 12">
            <router-link
              class="all-categories_icon"
              :to="cat.seo_name"
              :title="cat.category"
            >
              <i class="dsi dsi-s48" :class="getCategoryIcon(cat.category_id)"></i>
            </router-link>
            <router-link
              class="all-categories_name"
              :to="_categoryUrl(cat)"
              :title="cat.category"
              >{{ cat.category }}</router-link
            >
            <div class="all-categories_numbers">
              {{ numberFormat(cat.product_count) }} sản phẩm
            </div>
          </li>
        </template>
      </ul>
    </section>

    <!-- <Intersection @onIntersection="onIntersectionBrandNew()">
      <SliderBrandSection
        title="Thương hiệu mới"
        sectionClass="brand-main-page-section"
        :brands="state.brandsNewArrival"
        link="/brands-newarrival"
      />
    </Intersection> -->

    <Intersection @onIntersection="onIntersectionProductNewArrival()">
      <ProductsSliderVertical
        wrapperClass="page-section"
        title="Sản phẩm mới"
        :showLink="true"
        :link="`/new-arrival`"
        :products="state.productsNewArrival"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionBrandSugestion()">
      <SliderBrandSuggestion
        title="Thương hiệu bạn có thể biết"
        :brands="state.brandsSugestion"
      />
    </Intersection>

    <div class="page-section">
      <div class="section-title-wrapper">
        <h2 class="section-title section-title-product">Gợi ý cho bạn</h2>
      </div>

      <ProductLink
        gridClass="grid-50"
        :query="{
          sort_by: 'random',
        }"
        link="/daily-suggest"
      />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import MainSlider from "@/components/sliders/MainSlider";
import HotDeal from "@/components/events/HotDeal";
import CategoriesListSkeletons from "@/components/skeletons/sections/CategoriesList";
import ProductsSliderVertical from "@/components/sliders/ProductsVertical";
import SliderBrandSection from "@/components/sliders/BrandsHorizon";
import SliderTabProductCategories from "@/components/sliders/SliderTabProductCategories";
import SliderBrandSuggestion from "@/components/sliders/BrandsSuggestion";

import ProductLink from "@/components/product/ProductLink";

import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { _getCookie } from "@/utlis/cookie";
import aa from "search-insights";

export default {
  name: "home",
  components: {
    MainSlider,
    HotDeal,
    ProductLink,
    SliderBrandSection,
    CategoriesListSkeletons,
    ProductsSliderVertical,
    SliderTabProductCategories,
    SliderBrandSuggestion,
  },
  setup() {
    const currentUser = inject("currentUser");
    const state = inject("state");
    const onIntersectionListCategory = inject("onIntersectionListCategory");
    const onIntersectionBrandNew = inject("onIntersectionBrandNew");
    const onIntersectionProductNewArrival = inject("onIntersectionProductNewArrival");
    const getFocusBanners = inject("getFocusBanners");
    // const onIntersectionCategoriesBest = inject('onIntersectionCategoriesBest');
    const onIntersectionBrandSugestion = inject("onIntersectionBrandSugestion");
    const onIntersectionBanners = inject("onIntersectionBanners");
    const options = {
      autoplay: {
        delay: 3500,
      },
      loop: true,
      slidesPerView: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => `
                        <span class="${className}"><span class="indicator-text">${addLeadingZero(
          index + 1
        )}</span><span class="separator">-</span></span>
                    `,
      },
    };
    const insightsMiddleware = createInsightsMiddleware({
      insightsClient: aa,
    });

    aa("setUserToken", currentUser.value ? currentUser.value.user_id : _getCookie("dsi"));

    const focusOptions = {
      autoplay: {
        delay: 3500,
      },
      loop: true,
      slidesPerView: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    };

    return {
      state,
      options,
      focusOptions,
      currentUser,
      onIntersectionBrandNew,
      onIntersectionListCategory,
      onIntersectionProductNewArrival,
      // onIntersectionCategoriesBest,
      getFocusBanners,
      middlewares: [insightsMiddleware],
      onIntersectionBrandSugestion,
      onIntersectionBanners
    };
  },
};
</script>
