<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <!-- <MainSlider
      :banners="state.banners"
      :options="options"
      :height="425"
      sectionClass="page-section dosiin_pl-0 dosiin_pr-0"
      wrapperClass="main-page-slider overflow"
      swiperClass="swiper-wrapper dosiin_swiper-effect-coverflow"
    /> -->

    <Intersection @onIntersection="onIntersectionBanners()">
      <MainSlider
        :banners="state.banners"
        :options="options"
        :height="425"
        sectionClass="page-section dosiin_pl-0 dosiin_pr-0"
        wrapperClass="main-page-slider overflow"
        swiperClass="swiper-wrapper dosiin_swiper-effect-coverflow"
        :templateBlock="false"
      />
    </Intersection>

    <HotDealPC />

    <!-- <Intersection @onIntersection="onIntersectionCategoriesBest()"> -->
    <SliderTabProductCategories
      title="Sản phẩm bán chạy"
      link="/new-arrival"
      :categories="state.categoriesProductsBest"
    />
    <!-- </Intersection> -->

    <!-- <Intersection @onIntersection="getFocusBanners(605)"> -->
    <Intersection @onIntersection="getFocusBanners()">
      <section
        class="page-section product-bestselling-section"
        v-if="state.forcusBanner.length === 0"
      >
        <div class="home_section-inner">
          <Skeletor class="section-title" width="30%" />
          <div class="banner_focus-body">
            <div class="banner_focus-item" v-for="i in 2" :key="i">
              <Skeletor width="100%" height="278" />
            </div>
          </div>
        </div>
      </section>

      <section v-else class="page-section product-bestselling-section">
        <div class="home_section-inner">
          <div class="section-title-wrapper">
            <h2 class="section-title">Tiêu điểm</h2>
          </div>
          <div class="banner_focus-body">
            <div
              class="banner_focus-item"
              v-for="(banner, i) in state.forcusBanner.slice(0, 2)"
              :key="i"
            >
              <router-link
                :to="banner.company_data.seo_name ? banner.company_data.seo_name : '#'"
                :title="banner.company"
              >
                <!-- <Banner :pairsObject="banner.company_data.banners_main" /> -->
                <img
                  v-lazy="{
                    src: _brandBannerCrop(banner.company_data.banners_main),
                    loading: _bannerDefault,
                    error: _bannerDefault,
                  }"
                  class="dosiin_w-100"
                />
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </Intersection>

    <CategoriesSkeletons v-if="state.categories.length === 0" />
    <section v-else class="page-section product-bestselling-section">
      <div class="home_section-inner">
        <div class="section-title-wrapper">
          <h2 class="section-title">Top danh mục</h2>
        </div>
        <ul class="all-categories_content all-categories_content-list">
          <template v-for="(cat, i) in state.categories">
            <li class="dosiin_all-categories" :key="i" v-if="i < 15">
              <router-link
                :to="cat.seo_name"
                class="all-categories center all-category_item"
              >
                <div class="category_box-body">
                  <div class="all-categories_icon">
                    <i class="dsi dsi-s36" :class="getCategoryIcon(cat.category_id)"></i>
                  </div>
                  <div class="category_box-txt">
                    <div class="all-categories_name">{{ cat.category }}</div>
                    <div class="all-categories_numbers">
                      <b>{{ numberFormat(cat.product_count) }}</b> sản phẩm
                    </div>
                  </div>
                </div>
                <i class="dsi dsi-chevron-right"></i>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </section>

    <!-- <Intersection @onIntersection="onIntersectionBrandNew()">
      <SliderBrandSection
        title="Thương hiệu mới"
        sectionClass="brand-main-page-slider dosiin_mb-16"
        link="/brands-newarrival"
        :brands="state.brandsNewArrival"
      />
    </Intersection> -->

    <Intersection @onIntersection="onIntersectionProductNewArrival()">
      <ProductsSliderHorizon
        wrapperClass="page-section product-main-page-section favorite-list-section"
        title="Sản phẩm mới"
        gridClass="grid-20"
        :showSlideLink="true"
        :link="'/new-arrival'"
        :products="state.productsNewArrival"
        :options="{
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 16,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }"
      />
    </Intersection>

    <Intersection @onIntersection="onIntersectionBrandSugestion()">
      <SliderBrandSuggestionPC
        title="Thương hiệu bạn có thể biết"
        :brands="state.brandsSugestion"
      />
    </Intersection>

    <section class="page-section">
      <div class="section-title-wrapper">
        <h2 class="section-title section-title-product">Gợi ý cho bạn</h2>
      </div>

      <ProductLink
        :query="{
          sort_by: 'random',
        }"
        link="/daily-suggest"
      />
    </section>
  </div>
</template>

<script>
import { inject } from "vue";
import MainSlider from "@/components/sliders/MainSlider";
import HotDealPC from "@/components/events/HotDealPC";
import ProductItemSkeleton from "@/components/skeletons/product/ProductItem";
import SectionHeader from "@/components/skeletons/sections/SectionHeader";

import CategoriesSkeletons from "@/components/skeletons/sections/CategoriesRectangular";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import Banner from "@/components/images/Banner";
import SliderTabProductCategories from "@/components/sliders/SliderTabProductCategories";
import SliderBrandSuggestionPC from "@/components/sliders/BrandsSuggestionPC";
import SliderBrandSection from "@/components/sliders/BrandsHorizon";

// products //
import ProductLink from "@/components/product/ProductLink";
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { _getCookie } from "@/utlis/cookie";
import aa from "search-insights";
import { _addLeadingZero } from "@/utlis/helpers";
export default {
  name: "home",
  components: {
    MainSlider,
    HotDealPC,
    ProductItemSkeleton,
    CategoriesSkeletons,
    SectionHeader,
    ProductsSliderHorizon,
    Banner,
    SliderTabProductCategories,
    SliderBrandSuggestionPC,
    SliderBrandSection,
    ProductLink,
  },
  setup() {
    const state = inject("state");
    const currentUser = inject("currentUser");
    const getFocusBanners = inject("getFocusBanners");
    // const onIntersectionCategoriesBest = inject('onIntersectionCategoriesBest');
    const onIntersectionProductNewArrival = inject("onIntersectionProductNewArrival");
    const onIntersectionBrandSugestion = inject("onIntersectionBrandSugestion");
    const onIntersectionBrandNew = inject("onIntersectionBrandNew");
    const onIntersectionBanners = inject("onIntersectionBanners");
    const options = {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflowEffect: {
        rotate: 0,
        stretch: 370,
        depth: 250,
        modifier: 1.5,
        slideShadows: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => `
                        <span class="${className}"><span class="indicator-text">${_addLeadingZero(
          index + 1
        )}</span><span class="separator">-</span></span>
                    `,
      },
      breakpoints: {
        1600: {
          coverflowEffect: {
            rotate: 0,
            stretch: 850,
            depth: 0,
            modifier: 1,
            scale: 0.8,
            slideShadows: true,
          },
        },
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    const insightsMiddleware = createInsightsMiddleware({
      insightsClient: aa,
    });

    aa("setUserToken", currentUser.value ? currentUser.value.user_id : _getCookie("dsi"));

    return {
      state,
      options,
      getFocusBanners,
      onIntersectionBrandSugestion,
      onIntersectionBrandNew,
      middlewares: [insightsMiddleware],
      onIntersectionProductNewArrival,
      onIntersectionBanners,
    };
  },
};
</script>
<style></style>
