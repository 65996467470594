<template>
  <!-- <SliderTabProductCategories v-if="categories.length === 0" /> -->
  <!-- <section v-else class="page-section product-bestselling-section"> -->
  <ais-instant-search :search-client="searchClientKeys" index-name="dev_categories">
    <ais-configure :hitsPerPage="20" />
    <ais-hits
      :class-names="{
        'ais-Hits': 'page-section product-bestselling-section',
      }"
    >
      <template v-slot="{ items }">
        <div class="section-header">
          <h2 class="section-title" v-text="title"></h2>
        </div>
        <Swiper
          @swiper="setThumbsSwiper"
          :modules="[FreeMode, Thumbs]"
          :spaceBetween="16"
          :slidesPerView="'auto'"
          :slideToClickedSlide="false"
          :freeMode="true"
          :allowTouchMove="true"
          watch-slides-progress
          class="swiper-pagination swiper-button-pagination"
        >
          <SwiperSlide v-for="(category, index) in items" :key="index">
            <span v-text="category.category"></span>
          </SwiperSlide>
        </Swiper>

        <Swiper
          class="product-bestselling-swipable-slider"
          :modules="[Thumbs, Navigation]"
          :slidesPerView="1"
          :spaceBetween="8"
          :thumbs="{ swiper: thumbsSwiper }"
        >
          <SwiperSlide v-for="(category, index) in items" :key="index">
            <template v-for="(product, index2) in category.products" :key="index2">
              <div v-if="layout == 4" class="list-item product-list-item">
                <router-link
                  class="list-item_img bestsellers-list-item_img"
                  :to="_productUrl(product)"
                  :title="product.product"
                >
                  <img
                    v-lazy="
                      _productThumbnailSticker(
                        product.main_pair,
                        product.sticker.main_pair
                      )
                    "
                    width="68"
                    height="68"
                    :alt="product.product"
                    :title="product.product"
                  />
                </router-link>
                <dl class="list-item_summary">
                  <dt>
                    <router-link
                      class="list-item_brand"
                      :to="_brandUrl(product.seo_name_company)"
                      :title="product.company_name"
                      v-text="product.company_name"
                    ></router-link>
                  </dt>
                  <dd class="list-item_name">
                    <router-link
                      :to="_productUrl(product)"
                      :title="product.product"
                      v-text="product.product"
                    ></router-link>
                  </dd>
                  <div class="list-item_sale product-list-item_price_wrapper">
                    <dd class="list-item_price">{{ numberFormat(product.price) }}₫</dd>
                    <template v-if="product.list_price > product.price">
                      <dd class="inline-bl list-item_sale_origin">
                        {{ numberFormat(product.list_price) }}₫
                      </dd>
                      <dd class="inline-bl list-item_sale_discount">
                        -{{ discountPrc(product) }}%
                      </dd>
                    </template>
                  </div>
                </dl>
              </div>
              <ProductItem v-else :product="product" />
            </template>
            <div v-if="layout == 4" class="seemore-btn">
              <router-link
                class="primary-button button"
                :to="category.seo_name"
                :title="category.category"
              >
                <span class="primary-link_text">Xem tất cả</span>
                <i class="dsi dsi-act-arrow-forward"></i>
              </router-link>
            </div>
          </SwiperSlide>
        </Swiper>
      </template>
    </ais-hits>
  </ais-instant-search>
  <!-- </section> -->
</template>

<script>
import SliderTabProductCategories from "@/components/skeletons/sliders/SliderTabProductCategories";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { toRefs, ref } from "vue";
import ProductItem from "@/components/product/ProductItem";
import ProductItemHorizon from "@/components/product/ProductItemHorizon";

export default {
  components: {
    ProductItem,
    ProductItemHorizon,
    SliderTabProductCategories,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "/",
    },
    // categories : {
    //     type : [Array , Object],
    //     default : []
    // }
  },
  setup(props) {
    let thumbsSwiper = ref(null);

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    return {
      thumbsSwiper,
      setThumbsSwiper,
      ...toRefs(props),
      FreeMode,
      Navigation,
      Thumbs,
    };
  },
};
</script>
