<template>
    <section class="recommend-brands-section">
        <Skeletor class="section-title" width="30%" height="21" pill style="margin: 24px 40px 12px;" />

        <div class="dosiin_recommend-brands_swiper recommend-brands_swiper swiper-fade swiper-initialized swiper-horizontal swiper-pointer-events">
            <div class="swiper-wrapper">
                <div class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active">
                        <Skeletor width="100%" :height="layout ==4 ? 300 : 561"/>
                </div>
            </div>
        </div>

        <div class="dosiin_recommend-brands_swiper-thumbs recommend-brands_swiper-thumbs">

        </div>
    </section>

</template>

<script>
    import { toRefs } from 'vue';
    export default {
        props:['height','width'],
        setup(props) {
            return {
                ...toRefs(props)
            }
        },
    }
</script>