<template>
  <MainSliderBrand v-if="brands.length === 0" />

  <section
    v-else
    class="recommend-brands-section overflow"
    :class="{ 'dosiin_mb-16': layout != 4 }"
  >
    <h2 class="section-title" v-text="title"></h2>
    <Swiper
      @swiper="setControlledSwiper2"
      :modules="[Controller, Navigation]"
      class="dosiin_recommend-brands_swiper recommend-brands_swiper"
      :controller="{ control: controlledSwiper }"
      :loop="recommendSwiperOpts.loop"
      :loopedSlides="recommendSwiperOpts.loopedSlides"
      :navigation="recommendSwiperOpts.navigation"
      :effect="recommendSwiperOpts.effect"
      :fadeEffect="recommendSwiperOpts.fadeEffect"
    >
      <SwiperSlide class="swiper-slide" v-for="(brand, i) in brands" :key="i">
        <div class="img-slide">
          <img
            v-lazy="{
              src: _brandBanner(brand.banner_shop[Object.keys(brand.banner_shop)[0]]),
              loading: _bannerDefault,
              error: _bannerDefault,
            }"
            class="dosiin_w-100 styled-img"
          />
        </div>
      </SwiperSlide>

      <div v-if="layout != 4" class="swiper-button-next dosiin_swiper-button"></div>
      <div v-if="layout != 4" class="swiper-button-prev dosiin_swiper-button"></div>
    </Swiper>

    <Swiper
      class="dosiin_recommend-brands_swiper-thumbs recommend-brands_swiper-thumbs overflow-unset"
      @swiper="setControlledSwiper"
      :controller="{ control: controlledSwiper2 }"
      :modules="[Controller]"
      :loop="recommendSwiperThumbsOpts.loop"
      :loopedSlides="recommendSwiperThumbsOpts.loopedSlides"
      :centeredSlides="recommendSwiperThumbsOpts.centeredSlides"
      :slideToClickedSlide="recommendSwiperThumbsOpts.slideToClickedSlide"
      :slidesPerView="recommendSwiperThumbsOpts.slidesPerView"
      :watchSlidesVisibility="recommendSwiperThumbsOpts.watchSlidesVisibility"
      :watchSlidesProgress="recommendSwiperThumbsOpts.watchSlidesProgress"
      :spaceBetween="recommendSwiperThumbsOpts.spaceBetween"
    >
      <SwiperSlide class="swiper-slide" v-for="(brand, i) in brands" :key="i">
        <div class="brand-item recommend-brand-item">
          <div class="recommend-brand-item_body">
            <div class="brand-item_img dosiin_mb-3">
              <BrandLogo :width="124" :height="124" :brand="brand" />
            </div>
            <router-link
              class="brand-item_info"
              :to="_brandUrl(brand.seo_name)"
              :title="brand.company"
            >
              <h5 class="brand-item_title truncate" v-text="brand.company"></h5>
              <dl class="brand-item_social">
                <dd>
                  <strong v-text="brand.like_count"></strong><span> lượt thích</span>
                </dd>
                <dd>
                  <strong v-text="brand.products_count"></strong><span> sản phẩm</span>
                </dd>
              </dl>
            </router-link>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<script>
import { toRefs, ref } from "vue";
import MainSliderBrand from "@/components/skeletons/sliders/MainSliderBrand";
import { Navigation, Controller } from "swiper";

export default {
  components: {
    MainSliderBrand,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    brands: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    const controlledSwiper = ref(null);
    const controlledSwiper2 = ref(null);

    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };

    const setControlledSwiper2 = (swiper) => {
      controlledSwiper2.value = swiper;
    };

    const recommendSwiperOpts = {
      loop: true,
      loopedSlides: 5,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    };

    const recommendSwiperThumbsOpts = {
      loop: true,
      loopedSlides: 5,
      centeredSlides: true,
      slideToClickedSlide: true,
      slidesPerView: Tygh.layout == 4 ? 5 : 10,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      spaceBetween: 48,
    };

    // recommendSwiper.controller.control = recommendSwiperThumbs;
    // recommendSwiperThumbs.controller.control = recommendSwiper;

    return {
      controlledSwiper,
      controlledSwiper2,
      recommendSwiperOpts,
      recommendSwiperThumbsOpts,
      setControlledSwiper,
      setControlledSwiper2,
      ...toRefs(props),
      Navigation,
      Controller,
    };
  },
};
</script>
