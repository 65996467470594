<template>
  <section :class="sectionClass">
    <div class="section-title-slider" v-if="title">
      <h2
        class="header-section-left-side"
        :class="{ 'section-title': layout == 4 }"
        v-text="title"
      ></h2>

      <div class="header-section-right-side" v-if="link">
        <router-link class="primary-button" :to="link" title="Xem thêm">
          <span class="primary-link_text">Xem thêm</span>
          <i class="dsi dsi-act-arrow-forward"></i>
        </router-link>
      </div>
    </div>

    <SliderSkeleton
      class="dosiin_swiper-main-brand"
      :height="layout == 4 ? 200 : 500"
      v-if="brands.length === 0"
    />
    <div
      v-else
      class="dosiin_swiper-container"
      :class="{ 'swiper-container': layout == 4 }"
    >
      <Swiper
        class="dosiin_swiper-main-brand swiper-wrapper"
        :class="{ swiper__customBrandMainPage: layout == 4 }"
        :modules="modules"
        :slidesPerView="options.slidesPerView"
        :centeredSlides="options.centeredSlides"
        :watchSlidesVisibility="options.watchSlidesVisibility"
        :watchSlidesProgress="options.watchSlidesProgress"
        :spaceBetween="options.spaceBetween"
        :pagination="options.pagination"
        :navigation="options.navigation"
        :loop="options.loop"
      >
        <SwiperSlide
          class="swiper-slide dosiin_brand-follow-wrapper"
          v-for="(brand, i) in brands"
          :key="i"
        >
          <div class="img-slide">
            <router-link
              :to="_brandUrl(brand.seo_name)"
              :title="brand.company"
              style="display: contents"
            >
              <img
                v-lazy="{
                  src: _brandBannerCrop(
                    brand.banner_shop[Object.keys(brand.banner_shop)[0]]
                  ),
                  loading: _bannerDefault,
                  error: _bannerDefault,
                }"
                class="dosiin_w-100 styled-img"
              />
            </router-link>
          </div>
          <div class="brand-new-brand">
            <div class="brand-new-brand_img-wrapper">
              <BrandLogo
                :brand="brand"
                :width="62"
                :height="62"
                imageClass="brand-new-brand_img"
              />
            </div>
            <div class="brand-new-brand_info dosiin_text-left">
              <h5 class="brand-new-brand_title dosiin_truncate-title-brand">
                <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                  {{ brand.company }}
                </router-link>
              </h5>
              <h6 class="brand-new-brand_subtitle">
                <span class="dosiin_brand-follow-number">{{ brand.follow_count }}</span>
                người theo dõi
              </h6>
              <div class="brand-new-brand_badge">
                <span class="brand-new-brand_badge-text">Thương hiệu mới</span>
              </div>
            </div>
            <div class="brand-new-brand_follow-btn">
              <FollowButton
                @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                class="brand_follow-btn dosiin_brand_follow-btn primary-button button follow-with-text-btn"
                followable_type="brand"
                :followable_id="brand.company_id"
                :is_follow="brand.is_follow"
              />
            </div>
          </div>
        </SwiperSlide>
        <div v-if="options.pagination" class="swiper-pagination"></div>
        <div v-if="layout != 4" class="swiper-button-next dosiin_swiper-button"></div>
        <div v-if="layout != 4" class="swiper-button-prev dosiin_swiper-button"></div>
      </Swiper>
    </div>
  </section>
</template>

<script>
import { toRefs } from "vue";
import SliderSkeleton from "@/components/skeletons/sliders/MainSliderMB";
import { Autoplay, Pagination, Navigation } from "swiper";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    sectionClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    brands: {
      type: Array,
      default: [],
    },
  },
  components: {
    SliderSkeleton,
  },
  setup(props) {
    const options = {
      loop: true,
      loopAdditionalSlides: 1,
      slidesPerView: Tygh.layout == 4 ? 1 : 1.5,
      watchSlidesVisibility: true,
      centeredSlides: true,
      watchSlidesProgress: true,
      spaceBetween: 16,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: (index, className) => `
                        <span class="${className}"><span class="indicator-text">${addLeadingZero(
          index + 1
        )}</span><span class="separator">-</span></span>
                    `,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    return {
      options,
      ...toRefs(props),
      modules: [Autoplay, Pagination, Navigation],
    };
  },
};
</script>
